/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

$(document).ready(function () {
    $('[data-toggle="popover"]').popover()
    bsCustomFileInput.init() // améliore l'affichage des file input dans les formulaires
});

const Packery = require('packery');

// ajouter imagesLoaded : https://imagesloaded.desandro.com/v3/#webpack

$(document).ready(function () {
    $('.skeleton').hide()
});

$(document).ready(function () {
    if (window.location.hash) {
        location.hash = window.location.hash
    }
});


/* COMPTEUR DE CARACTERES */

var maxLength = 140;

$('.limit_140').keyup(function () {
    var length = maxLength - $(this).val().length
    $('#chars').text(length)
});

$(document).ready(function () {
    var length = maxLength - $('.limit_140').val().length
    $('#chars').text(length)
});

// CHANGE LE BOUTON EN BAS DE LA PAGE .. Enlevé et mit sur la page module/show
/*
$(document).ready(function () {
    window.onscroll = function (ev) {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 180) {
            document.getElementById('bouton_idee').style.opacity = "0";
        } else {
            document.getElementById('bouton_idee').style.opacity = "1";
        }
    };
});
*/

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})

//
/* Réupèr l'id de l'idée lorsque l'on clique sur le bouton "Gérer membres" de la card
$(document).ready(function(){
    $('#modal_members').on('show.bs.modal', function () {
        var id = event.target.getAttribute('data-id');
        alert(id);
    });
});
*/

import toastr from 'toastr';

// Check if file input is present or not 
// Avoid error messages on pages without comment section 
if (document.getElementById('comment_imageFile')) {

    document.getElementById('comment_imageFile').addEventListener('change', e => {
        var uploader = document.getElementById('comment_imageFile').files[0];
        uploadFile(uploader);
    });
    
    document.getElementById('comment_documentFile').addEventListener('change', e => {
        var uploader = document.getElementById('comment_documentFile').files[0];
        uploadFile(uploader);
    });
    
    function uploadFile(uploader) {
        if(uploader != null) {
            toastr.success('Votre fichier a bien été téléchargé ! ');
        } else {
            toastr.error('Erreur lors du téléchargement.');
            return false;
        }
    }

}
